import { useEffect } from 'react';

export default () => {
    useEffect(() => {
        if (typeof window !== 'undefined')
            window.location.replace('https://wa.me/525513285364');
    }, []);

    return null;
};
